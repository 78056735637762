<template>
    <div class="result">
        <div class="top">
            <div class="dis_flex dis_sb">
                <router-link to="/new_menu">
                    <img class="logo" src="@/assets/new_img/img02.png" alt="" srcset="">
                </router-link>
                <div class="reset" @click="zsd_del">重置全部</div>
            </div>
            <div class="ul">
                <div class="li">
                    <div class="txt1">{{(json.jindu*100).toFixed(2)}}%</div>
                    <div class="txt2">学习进度 <img @click="goTo()" src="@/assets/new_img/img11.png" alt="" srcset=""></div>
                </div>
                <div class="li">
                    <div class="txt1">232</div>
                    <div class="txt2">进阶币 <img @click="goTo()" src="@/assets/new_img/img11.png" alt="" srcset=""></div>
                </div>
            </div>
        </div>
        <div class="body">
            <img src="@/assets/new_img/img01.png" alt="" @click="isShow = true">
            <div class="jdb">
                <div class="title">
                    <span class="txt">知识点进度表</span>
                    <div class="dis_flex">
                        <span class="sp sp1">已训练</span>
                        <span class="sp sp2">未训练</span>
                        <span class="sp sp3">训练中</span>
                    </div>
                </div>
                <div class="txt1">基础训练</div>
                <div class="list">
                    <div class="ll" v-for="list in json.fangkuai1" :class="list"></div>
                </div>
                <div class="txt1">综合提高</div>
                <div class="list">
                    <div class="ll" v-for="list in json.fangkuai2" :class="list"></div>
                </div>
                <div class="title">
                    <span class="txt">训练明细</span>
                </div>
                <div class="info">
                    <div class="info-list dis_flex dis_sb" v-for="list in json.details">
                        <div class="txt">{{list.name}}</div>
                        <div class="txt2">准确率-{{list.accu}}%</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="dialog" v-if="isShow">
            <div class="dialog_bg" @click="isShow = false"></div>
            <div class="dialog_body">
                <div class="p1">根据你的训练情况，预估得分为</div>
                <div class="p2">89</div>
                <div class="p3">置信度72.34%，快去做题提高吧~</div>
                <div class="btn" @click="isShow = false">知道了</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                json: {
                    jindu: 0
                },
                isShow: false,
            }
        },
        mounted () {
            this.getData();
        },
        methods: {
            zsd_del() {
                this.$api.zsd_del({}).then(res=>{
                    console.log(res);
                    this.$toast.success('重置成功！')
                    this.$router.push('/new_topic')
                })
            },
            getData() {
                this.$api.tuijian({}).then(res=>{
                    console.log('res', res)
                    this.json = res;
                })
            },
            goTo(){
                this.$router.push('/new_info')
            }
        },
    }
</script>

<style lang="less" scoped>
    .dialog{
        position: fixed;
        &_bg{
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: #000;
            opacity: 0.6;
            left: 0;
            top: 0;
        }
        &_body{
            width: 240px;
            height: 210px;
            background: #FFFFFF;
            box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .p1{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #9498AA;
                padding-top: 24px;
                padding-bottom: 8px;
            }
            .p2{
                font-family: 'DIN Alternate';
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 56px;
                text-align: center;
                color: #415FD5;
                padding-bottom: 4px;
            }
            .p3{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px; 
                line-height: 17px;
                color: #BFC1CC;
                padding-bottom: 16px;
            }
            .btn{
                width: 200px;
                height: 40px;
                background: #415FD5;
                border-radius: 24px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 40px;
                color: #FFFFFF;
                text-align: center;
                margin: 0 auto;
            }
        }
    }
    .result{
        .top{
            height: 170px;
            background: #415FD5;
            padding: 20px;
            .logo{
                width: 36px;
                height: 28px;
            }
            .reset{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.5);
            }
            .ul{
                padding-top: 32px;
                display: flex;
                .li{
                    width: 50%;
                    text-align: center;
                    .txt1{
                        font-family: 'DIN Alternate';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 33px;
                        text-align: center;
                        color: #F9C200;
                    }
                    .txt2{
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        color: #FFFFFF;
                        padding-top: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
        .body{
            padding: 24px 20px;
            margin-top: -20px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            background: #fff;
        }
        .title{
            text-align: left;
            margin-top: 16px;
            position: relative;
            display: flex;
            .txt{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #2A3155;
                padding-left: 12px;
            }
            &::before{
                content: '';
                width: 4px;
                height: 12px;
                background: #415FD5;
                border-radius: 4px;
                position: absolute;
                left: -2px;
                top: 3px;
            }
            .dis_flex{
                margin-left: 40px;
                .sp{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    margin-left: 16px;
                    color: #606580;
                    &::before{
                        content: '';
                        width: 8px;
                        height: 8px;
                        display: inline-block;
                        margin-right: 4px;
                    }
                    &.sp1{
                        &::before{
                            background: #15C392;
                        }
                    }
                    &.sp2{
                        &::before{
                            background: #EFF2F7;
                        }
                    }
                    &.sp3{
                        &::before{
                            background: #415FD5;
                        }
                    }
                }
            }
        }
        .txt1{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #9498AA;
            padding-top: 12px;
            padding-bottom: 8px;
            text-align: left;
        }
        .list{
            display: flex;
            flex-wrap: wrap;
            .ll{
                width: 10px;
                height: 10px;
                margin-right: 2px;
                margin-bottom: 2px;
                border-radius: 2px;
                background: #EFF2F7;
                &.yxl{
                    background: #15C392;
                    border-radius: 2px;
                }
                &.jxz{
                    background: #415FD5;;
                    border-radius: 2px;
                }
            }
        }
        .info{
            &-list{
                padding: 20px 0;
                border-bottom: 1px solid #E6E8EF;
                .txt{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #2A3155;
                }
                .txt2{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    text-align: right;
                    color: #15C392;
                }
            }
        }
    }
</style>